import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { tlds } from '@hapi/tlds';
import { joiResolver } from '@hookform/resolvers/joi';
import axios from 'axios';
// import { ReCAPTCHA } from 'react-google-recaptcha';

const requiredFieldDefault = '{{#label}} is a required field';
const schema = Joi.defaults((schema) =>
    schema.options({
        messages: {
            'string.max': '{{#label}} must not exceed {{#limit}} characters.',
            'any.required': requiredFieldDefault,
            'string.required': requiredFieldDefault,
            'string.empty': requiredFieldDefault,
            'number.required': requiredFieldDefault,
            'number.empty': requiredFieldDefault,
        },
        errors: {
            wrap: {
                label: undefined,
                array: undefined,
            },
        },
    })
).object({
    name: Joi.string().required().label('Name'),
    email: Joi.string()
        .email({ tlds: { allow: tlds } })
        .required()
        .label('Email'),
    phone: Joi.string()
        .pattern(/^(\+44|0)[\d\s]{10,15}$/)
        .trim()
        .min(10)
        .max(18)
        .label('Phone Number')
        .messages({
            'string.pattern.base': 'Please enter a valid UK telephone number',
            'string.empty': '{{#label}} is required',
            'string.min': '{{#label}} is required',
            'string.max': '{{#label}} cannot exceed 18 characters',
        }),
    message: Joi.string().required().label('Message'),
});

const ContactForm = ({ setShowContactForm }) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        clearErrors,
        setValue,
    } = useForm({ resolver: joiResolver(schema), mode: 'all' });

    const [formError, setFormError] = useState();
    const [formSuccess, setFormSuccess] = useState(false);

    const clearErrorsOnChange = (event) => {
        const fieldName = event.target.name;
        clearErrors(fieldName);
        setValue(fieldName, event.target.value);
    };

    const onSubmit = async (data) => {
        setFormError(null);
        setFormSuccess(false);
        console.log(data);
        // const isValidResponse = (response) => {
        //     if (!response.data.tokenProperties.valid) {
        //         throw new Error('Invalid response');
        //     }
        //     return true;
        // };

        // try {
        //     const response = await axios.post(
        //         'https://recaptchaenterprise.googleapis.com/v1/projects/e-fox-web-soluti-1700688955922/assessments?key=AIzaSyDjWAUO3NninRG9wR6FBw0YaTDX4T1DM-c',
        //         {
        //             event: {
        //                 token: token,
        //                 expectedAction: 'CONTACT',
        //                 siteKey: '6LfYQRkpAAAAALE-aooWTLEz37KBxFZKRb8aYryp',
        //             },
        //         }
        //     );
        //     console.log(response);
        //     isValidResponse(response);
        // } catch (e) {
        //     console.log(e);
        // }

        await window.grecaptcha.enterprise.ready(async () => {
            const token = await window.grecaptcha.enterprise.execute(
                // eslint-disable-next-line no-undef
                process.env.REACT_APP_RECAPTCHA_TOKEN,
                { action: 'CONTACT' }
            );
            try {
                await axios.post(
                    // eslint-disable-next-line no-undef
                    process.env.REACT_APP_CONTACT_FORM_POST_URL,
                    { token, ...data }
                );
                setFormSuccess(true);
            } catch (error) {
                console.log(error);
                setFormError(
                    'Something went wrong with your submission, please try again'
                );
            }
        });

        // const secretKey = 'YOUR_RECAPTCHA_SECRET_KEY'; // Replace with your reCAPTCHA Secret Key
        //
        // try {
        //     const response = await axios.post(
        //         'https://www.google.com/recaptcha/api/siteverify',
        //         {
        //             secret: secretKey,
        //             response: recaptchaResponse,
        //         }
        //     );
        //
        //     if (response.data.success) {
        //         // reCAPTCHA verification passed, proceed with form submission
        //         // Handle the form data (name, email, phone, message) here
        //         res.status(200).json({ success: true });
        //     } else {
        //         // reCAPTCHA verification failed
        //         res.status(400).json({
        //             success: false,
        //             error: 'reCAPTCHA verification failed',
        //         });
        //     }
        // } catch (error) {
        //     console.error('reCAPTCHA verification error:', error);
        //     res.status(500).json({
        //         success: false,
        //         error: 'Internal server error',
        //     });
        // }
    };

    const fieldCSSClass =
        'appearance-none w-full border-1 border-b-3 bg-black rounded-sm px-md focus:border-b-primary focus:outline-none';
    const inputCssClass = `${fieldCSSClass} h-14`;
    const errorCssClass = 'text-negative my-sm font-bold';

    if (formSuccess) {
        return (
            <div className={'border border-positive px-md py-sm'}>
                Your enquiry has been received. We endeavor to respond within 2
                working days.
            </div>
        );
    }

    return (
        <form
            role="form"
            method="post"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
        >
            <p className={'font-bold'}>
                All fields are mandatory unless otherwise stated
            </p>
            {formError && <p className={errorCssClass}>{formError}</p>}
            <div className={'mb-lg'}>
                <label className={'block mb-xs font-bold'}>Name</label>
                <input
                    className={inputCssClass}
                    {...register('name')}
                    onChange={(event) => clearErrorsOnChange(event)}
                />
                {errors.name && (
                    <p className={errorCssClass}>{errors.name.message}</p>
                )}
            </div>

            <div className={'mb-lg'}>
                <label className={'block mb-xs font-bold'}>Email</label>
                <input
                    className={inputCssClass}
                    onChange={(event) => clearErrorsOnChange(event)}
                    {...register('email')}
                />
                {errors.email && (
                    <p className={errorCssClass}>{errors.email.message}</p>
                )}
            </div>

            <div className={'mb-lg'}>
                <label className={'block mb-xs font-bold'}>Phone Number</label>
                <input
                    className={inputCssClass}
                    {...register('phone')}
                    onChange={(event) => clearErrorsOnChange(event)}
                />
                {errors.phone && (
                    <p className={errorCssClass}>{errors.phone.message}</p>
                )}
            </div>

            <div className={'mb-lg'}>
                <label className={'block mb-xs font-bold'}>Message</label>
                <textarea
                    className={`${fieldCSSClass} h-40 py-md`}
                    {...register('message')}
                    onChange={(event) => clearErrorsOnChange(event)}
                />
                {errors.message && (
                    <p className={errorCssClass}>{errors.message.message}</p>
                )}
            </div>
            <div className={'flex justify-between'}>
                {setShowContactForm && (
                    <button
                        type={'button'}
                        className={
                            'text-white border-2 border border-white min-w-128 bg-transparent'
                        }
                        onClick={(event) => {
                            event.preventDefault();
                            setShowContactForm(false);
                        }}
                    >
                        Back
                    </button>
                )}

                <button type="submit" className={'btn primary'}>
                    Submit
                </button>
            </div>
        </form>
    );
};

export default ContactForm;
