import mobilesWebDesign from '../img/mobiles-web-design.png';
import beaconWebDesign from '../img/beacon-web-design.png';
import hhWebDesign from '../img/hh-web-design.png';
import mobiles from '../svg/mobiles-logo.svg';
import mobilesMobileFirst from '../img/mobiles-mobile-first.png';
import mobilesDesktopPhone from '../img/mobiles-desktop-phone.png';
import mobilesComparasion from '../img/mobiles-comparasion.png';
import { useEffect, useState } from 'react';

function WebDevelopment() {
    const [imageIndex, setImageIndex] = useState(0);
    const images = [mobilesWebDesign, beaconWebDesign, hhWebDesign];

    useEffect(() => {
        // Set up an interval to change the image every 2000 milliseconds (2 seconds)
        const intervalId = setInterval(() => {
            setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2500);

        // Clear the interval on component unmount to prevent memory leaks
        return () => clearInterval(intervalId);
    }, [imageIndex, images.length]);

    return (
        <>
            <div
                className={
                    'bg-iso min-h-screen flex justify-center items-center pt-28 pb-lg xl:pb-0'
                }
            >
                <div className={'max-w-768 px-3xl mx-auto'}>
                    <div className={'text-center md:text-left'}>
                        <img
                            src={images[imageIndex]}
                            className={'w-md mb-lg'}
                            alt="Responsive Design"
                        />
                        <h1 className={'main-heading'}>Web Development</h1>
                        <span
                            className={'text-white text-base block font-normal'}
                        >
                            <p>
                                {
                                    "Our web development services focus on creating visually appealing and user-friendly websites that adapt to the device in use, whether it's a mobile, tablet, desktop, or anything in between."
                                }
                            </p>
                            <p>
                                E-Fox Web Solutions ensures your web presence is
                                accessible to all, delivering a seamless user
                                experience.
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <div className={'bg-black py-2xl text-center'}>
                <div className={'p-md px-xl'}>
                    <div
                        className={
                            'block border-animation p-lg bg-code hover:no-underline text-white h-full'
                        }
                    >
                        <div
                            className={
                                'max-w-768 mx-auto flex justify-center pt-md mb-xs'
                            }
                        >
                            <img
                                src={mobiles}
                                className={'w-80'}
                                alt="Mobiles.co.uk"
                            />
                        </div>
                        <h2
                            className={
                                'inline-block border-b border-b-primary pt-md pb-sm mb-2xl'
                            }
                        >
                            Case Study: Mobiles.co.uk
                        </h2>
                        <div className={'mx-auto max-w-768'}>
                            <p>
                                Mobiles.co.uk, a prominent online retailer and a
                                member of the Dixons Carphone group, found the
                                need for a fresh website. Leveraging
                                cutting-edge responsive technology, we
                                successfully revitalized the visual identity of
                                Mobiles.co.uk. Our focus was not only on
                                elevating the aesthetics but also on
                                prioritizing enhanced functionality and an
                                improved user experience.
                            </p>
                        </div>

                        <div
                            className={
                                'grid xl:grid-cols-3 px-md text-center pt-md md:pb-2xl'
                            }
                        >
                            <div
                                className={
                                    'px-md sm:h-full flex flex-col justify-end pb-xl mb-xl border-b-1 border-b-primary md:border-b-0 md:pb-0'
                                }
                            >
                                <div
                                    className={
                                        'grow flex items-center justify-center'
                                    }
                                >
                                    <div>
                                        <img
                                            src={mobilesComparasion}
                                            className={
                                                'w-96 mx-auto mb-lg md:mb-2xl'
                                            }
                                            alt="Mobiles.co.uk"
                                        />
                                    </div>
                                </div>
                                <p>
                                    Ensuring customers can easily navigate and
                                    compare various aspects of mobile contracts,
                                    such as tariffs and networks.
                                </p>
                            </div>
                            <div
                                className={
                                    'px-md sm:h-full flex flex-col justify-center pb-xl mb-xl border-b-1 border-b-primary md:border-b-0 md:pb-0'
                                }
                            >
                                <div
                                    className={
                                        'grow flex items-center justify-center'
                                    }
                                >
                                    <img
                                        src={mobilesMobileFirst}
                                        className={
                                            'w-40 md:w-48 mx-auto mb-lg md:mb-2xl'
                                        }
                                        alt="Mobiles.co.uk"
                                    />
                                </div>
                                <p>
                                    {`Adopting a mobile-first design to optimize
                                    performance and enhance visual appeal,
                                    acknowledging the website's primary avenue
                                    for sales.`}
                                </p>
                            </div>
                            <div
                                className={
                                    'px-md sm:h-full flex flex-col justify-end mb-xl'
                                }
                            >
                                <div
                                    className={
                                        'grow flex items-end justify-center'
                                    }
                                >
                                    <img
                                        src={mobilesDesktopPhone}
                                        className={
                                            'w-full px-md mx-auto mb-lg md:mb-2xl'
                                        }
                                        alt="Mobiles.co.uk"
                                    />
                                </div>
                                <p>
                                    Implementing an intuitively designed
                                    checkout which seamlessly streamlines
                                    purchases cross-platform led to a
                                    substantial boost in conversion rates.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WebDevelopment;
