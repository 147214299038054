import healthAndHer from '../svg/health-and-her.svg';
import hhSymptomTool from '../img/hh-symptom-tool.png';
import hhSymptomResults from '../img/hh-symptom-results.png';

import hhAppScreen1 from '../img/app/hh-app-screens-1.png';
import hhAppScreen2 from '../img/app/hh-app-screens-2.png';
import hhAppScreen3 from '../img/app/hh-app-screens-3.png';
import hhAppScreen4 from '../img/app/hh-app-screens-4.png';
import hhAppScreen5 from '../img/app/hh-app-screens-5.png';
import hhAppScreen6 from '../img/app/hh-app-screens-6.png';
import hhAppScreen7 from '../img/app/hh-app-screens-7.png';
import hhAppScreen8 from '../img/app/hh-app-screens-8.png';
import hhAppScreen9 from '../img/app/hh-app-screens-9.png';
import hhAppScreen10 from '../img/app/hh-app-screens-10.png';
import hhAppScreen11 from '../img/app/hh-app-screens-11.png';
import hhAppScreen12 from '../img/app/hh-app-screens-12.png';
import hhAppScreen13 from '../img/app/hh-app-screens-13.png';

import { useEffect, useState } from 'react';

function AppDevelopment() {
    const [imageIndex, setImageIndex] = useState(0);
    const images = [
        hhAppScreen1,
        hhAppScreen2,
        hhAppScreen3,
        hhAppScreen4,
        hhAppScreen5,
        hhAppScreen6,
        hhAppScreen7,
        hhAppScreen8,
        hhAppScreen9,
        hhAppScreen10,
        hhAppScreen11,
        hhAppScreen12,
        hhAppScreen13,
    ];

    useEffect(() => {
        // Set up an interval to change the image every 2000 milliseconds (2 seconds)
        const intervalId = setInterval(() => {
            setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 1750);

        // Clear the interval on component unmount to prevent memory leaks
        return () => clearInterval(intervalId);
    }, [imageIndex, images.length]);

    return (
        <>
            <div
                className={
                    'bg-iso min-h-screen flex justify-center items-center pt-28 pb-lg xl:pb-0 flex-wrap'
                }
            >
                <div>
                    <img
                        src={images[imageIndex]}
                        className={'w-40 lg:w-52 mx-auto mb-lg'}
                        alt="App Development"
                    />
                </div>
                <div className={'flex items-center justify-center'}>
                    <div className={'max-w-768 px-2xl mx-auto'}>
                        <div className={'text-center md:text-left'}>
                            <h1 className={'main-heading'}>App Development</h1>
                            <span
                                className={
                                    'text-white text-base block font-normal'
                                }
                            >
                                <p>
                                    {
                                        'We excel in dynamic solutions for web and mobile platforms, leveraging React JS and React Native. We provide comprehensive app development services, delivering seamless single-page applications with expert API integration.  '
                                    }
                                </p>
                                <p>
                                    Our solutions are versatile, compatible with
                                    both Android and Apple platforms, ensuring
                                    broad accessibility. Committed to
                                    innovation, our team tailors solutions to
                                    meet your business objectives and captivate
                                    users across diverse devices.
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'bg-black py-2xl text-center'}>
                <div className={'p-md px-xl'}>
                    <div
                        className={
                            'block border-animation p-lg bg-code hover:no-underline text-white h-full'
                        }
                    >
                        <div
                            className={
                                'max-w-768 mx-auto flex justify-center pt-md mb-xs'
                            }
                        >
                            <img
                                src={healthAndHer}
                                className={'w-96'}
                                alt="Mobiles.co.uk"
                            />
                        </div>
                        <h2
                            className={
                                'inline-block border-b border-b-primary pt-md pb-sm mb-2xl'
                            }
                        >
                            Case Study: Health &amp; Her
                        </h2>
                        <div className={'mx-auto max-w-768'}>
                            <p>
                                Health and Her, a pioneering womens health
                                company, collaborated with us to enhance their
                                digital presence and user experience. We
                                successfully redeveloped their website, updated
                                their Menopause App, and enhanced their symptom
                                checker using modern technologies like React.
                                The integration across these platforms resulted
                                in efficient data capture and reuse.
                            </p>
                            {/*<p>*/}
                            {/*    Additionally, we updated their Menopause App,*/}
                            {/*    introducing enhanced features and ensuring*/}
                            {/*    compatibility with modern platforms. The symptom*/}
                            {/*    checker received a comprehensive update,*/}
                            {/*    leveraging newer technologies to provide a more*/}
                            {/*    intuitive and user-friendly experience.*/}
                            {/*</p>*/}
                            <p>
                                {`Our commitment to refining digital solutions
                                aligns seamlessly with Health and Her's mission
                                to revolutionize menopause care, offering women
                                cutting-edge tools and resources for their
                                health journey.`}
                            </p>
                        </div>

                        <div
                            className={
                                'grid xl:grid-cols-3 px-md text-center pt-md md:pb-2xl'
                            }
                        >
                            <div
                                className={
                                    'px-md sm:h-full flex flex-col justify-end pb-xl mb-xl border-b-1 border-b-primary md:border-b-0 md:pb-0'
                                }
                            >
                                <div
                                    className={
                                        'grow flex items-center justify-center'
                                    }
                                >
                                    <div>
                                        <img
                                            src={hhAppScreen5}
                                            className={
                                                'w-48 mx-auto mb-lg md:mb-2xl'
                                            }
                                            alt="Mobiles.co.uk"
                                        />
                                    </div>
                                </div>
                                <p>
                                    Our work on the Menopause App focused on
                                    improving the logging features, providing
                                    users with a seamless experience for
                                    menopause symptom and period tracking.
                                </p>
                            </div>
                            <div
                                className={
                                    'px-md sm:h-full flex flex-col justify-end pb-xl mb-xl border-b-1 border-b-primary md:border-b-0 md:pb-0'
                                }
                            >
                                <div className={'grow flex items-center'}>
                                    <img
                                        src={hhSymptomTool}
                                        className={
                                            'w-40 md:w-96 mx-auto mb-lg md:mb-2xl'
                                        }
                                        alt="Mobiles.co.uk"
                                    />
                                </div>
                                <p>
                                    {`We enhanced Health and Her's symptom checker
                                    tool for a more intuitive and personalized
                                    experience, accurately identifying and
                                    providing support for womens symptoms and
                                    stages of menopause.`}
                                </p>
                            </div>
                            <div
                                className={
                                    'px-md sm:h-full flex flex-col justify-end mb-xl'
                                }
                            >
                                <div>
                                    <img
                                        src={hhSymptomResults}
                                        className={
                                            'w-full px-md mx-auto mb-lg md:mb-2xl'
                                        }
                                        alt="Mobiles.co.uk"
                                    />
                                </div>
                                <p>
                                    Integrating the symptom checker with the
                                    website enabled dynamic result generation
                                    with links to relevant products &amp;
                                    advice, ensuring comprehensive support for
                                    managing symptoms and access to valuable
                                    resources.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AppDevelopment;
