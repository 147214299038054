import { Link } from 'react-router-dom';

function FourZeroFour() {
    return (
        <div
            className={
                'flex grow items-center justify-center py-md bg-trans w-full px-3xl text-center'
            }
        >
            <div className={'py-2xl'}>
                <h1 className={'main-heading'}>404 Page Not Found</h1>
                <span className={'text-white text-base block font-normal'}>
                    <p>The page you were looking for could not be found</p>
                    <div>
                        <Link to={'/'}>
                            <button className={'btn'}>Go to Homepage</button>
                        </Link>
                    </div>
                </span>
            </div>
        </div>
    );
}

export default FourZeroFour;
