import avivaLogo from '../svg/aviva.svg';
import avivaWireframing from '../img/aviva-wireframing.png';
import avivaFirstDirect from '../img/aviva-first-direct-white-label.png';
import avivaDevicesAdded from '../img/aviva-devices-added.png';
import appUx1 from '../img/app-ux-1.png';
import appUx2 from '../img/app-ux-2.png';
import appUxBg from '../img/app-ux-bg.png';
function UXDesign() {
    return (
        <>
            <div
                className={
                    'bg-ux min-h-screen flex justify-center items-center pt-28 flex-wrap'
                }
            >
                <div className={'relative'}>
                    <img
                        src={appUx1}
                        className={
                            'left-0 top-0 absolute fade-image w-full z-20'
                        }
                        alt="App Development"
                    />
                    <img
                        src={appUx2}
                        className={'left-0 top-0 absolute w-full z-10'}
                        alt="Mobiles.co.uk"
                    />
                    <img
                        src={appUxBg}
                        className={'w-40 lg:w-48 mx-auto mb-lg z-0'}
                        alt="Mobiles.co.uk"
                    />
                </div>
                <div className={'flex items-center justify-center'}>
                    <div className={'max-w-768 px-2xl mx-auto'}>
                        <div className={'text-center md:text-left'}>
                            <h1 className={'main-heading'}>UX Design</h1>
                            <span
                                className={
                                    'text-white text-base block font-normal'
                                }
                            >
                                <p>
                                    E-Fox Web Solutions offer unparalleled UX
                                    design services. We specialize in the
                                    intricate art of wireframing, strategically
                                    laying the foundation for seamless and
                                    intuitive user interfaces. Beyond
                                    aesthetics, we delve into a comprehensive
                                    understanding of your business and its
                                    unique customer base, ensuring our designs
                                    resonate on a meaningful level.
                                </p>
                                <p>
                                    Our expertise extends to transforming these
                                    designs into tangible online products,
                                    spanning IoT devices, web interfaces, and
                                    cutting-edge mobile applications.
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'bg-black py-2xl text-center'}>
                <div className={'p-md px-xl'}>
                    <div
                        className={
                            'block border-animation p-lg bg-code hover:no-underline text-white h-full'
                        }
                    >
                        <div
                            className={
                                'max-w-768 mx-auto flex justify-center pt-md mb-xs'
                            }
                        >
                            <div className={'p-xs bg-aviva'}>
                                <img
                                    src={avivaLogo}
                                    className={'w-40'}
                                    alt="Aviva"
                                />
                            </div>
                        </div>
                        <h2
                            className={
                                'inline-block border-b border-b-primary pt-md pb-sm mb-2xl'
                            }
                        >
                            Case Study: Aviva Mobile Phone Insurance
                        </h2>
                        <div className={'mx-auto max-w-768'}>
                            <p>
                                {`In collaboration with Dixons Carphone, our
                                company overhauled the mobile phone
                                insurance service portal they provided to Aviva. Using insights from previous
                                analysis, we streamlined processes, allowing
                                users to effortlessly register devices and file
                                claims directly on the portal.`}
                            </p>
                            <p>
                                This user-centric approach resulted in a
                                significant reduction in support center calls,
                                delivering an intuitive and efficient insurance
                                experience.
                            </p>
                        </div>

                        <div
                            className={
                                'grid xl:grid-cols-3 px-md text-center pt-md md:pb-2xl'
                            }
                        >
                            <div
                                className={
                                    'px-md sm:h-full flex flex-col justify-end pb-xl mb-xl border-b-1 border-b-primary md:border-b-0 md:pb-0'
                                }
                            >
                                <div
                                    className={
                                        'grow flex items-center justify-center'
                                    }
                                >
                                    <img
                                        src={avivaWireframing}
                                        className={
                                            'w-60 md:w-96 mx-auto mb-lg md:mb-2xl'
                                        }
                                        alt="Mobiles.co.uk"
                                    />
                                </div>
                                <p>
                                    We conducted in-depth research on the
                                    existing mobile insurance platform to
                                    identify areas for improvement. Starting
                                    with wireframes and concepts, we worked
                                    closely with the business to refine and
                                    develop these solutions.
                                </p>
                            </div>
                            <div
                                className={
                                    'px-md sm:h-full flex flex-col justify-end pb-xl mb-xl border-b-1 border-b-primary md:border-b-0 md:pb-0'
                                }
                            >
                                <div className={'grow flex items-center'}>
                                    <img
                                        src={avivaDevicesAdded}
                                        className={
                                            'w-40 md:w-80 mx-auto mb-lg md:mb-2xl'
                                        }
                                        alt="Mobiles.co.uk"
                                    />
                                </div>
                                <p>
                                    {`We broadened the platform's scope to
                                    encompass insurance cover for multiple device types,
                                    extending its utility beyond mobile phones
                                    and encompassing a comprehensive user
                                    journey covering all gadget types`}
                                </p>
                            </div>
                            <div
                                className={
                                    'px-md sm:h-full flex flex-col justify-end mb-xl'
                                }
                            >
                                <div>
                                    <img
                                        src={avivaFirstDirect}
                                        className={
                                            'w-40 md:w-full px-3xl mx-auto mb-lg md:mb-2xl'
                                        }
                                        alt="Mobiles.co.uk"
                                    />
                                </div>
                                <p>
                                    {`To cater for Dixon's Carphone partners, the
                                    insurance platform was developed as a
                                    versatile white-label solution, allowing
                                    seamless branding customization for any
                                    brand to utilize the  as their
                                    own.`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UXDesign;
