// import phone from '../svg/phone.svg';
// import screen from '../svg/screen.svg';
import apple from '../svg/apple.svg';
import android from '../svg/android.svg';
import uxDesign from '../svg/ux-design.svg';
import screens from '../img/screens-blank.png';

import aviva from '../svg/aviva.svg';
import beaconInternational from '../svg/beacon-international.svg';
import carphoneWarehouse from '../svg/cpw-logo.svg';
import currys from '../svg/currys-logo.svg';
import firstDirect from '../svg/first-direct.svg';
import healthAndHerApp from '../img/healthandher-app.png';
import mobiles from '../svg/mobiles-logo.svg';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ContactForm from '../components/ContactForm/ContactForm';

function Home() {
    const [showContactForm, setShowContactForm] = useState(false);
    const logoCssClass = 'w-64 h-auto';
    const logoContainerCssClass =
        'flex items-center justify-center mx-3xl mb-xl';
    const ctaLinkCssClass =
        'block border-animation no-border-animation-hover p-lg bg-code cursor-pointer hover:no-underline text-white h-full';
    return (
        <div className={'bg-main'}>
            <div
                className={
                    'flex grow items-center py-md bg-trans max-w-768 px-3xl min-h-screen pt-28'
                }
            >
                <div className={'w-full py-2xl text-left'}>
                    {showContactForm ? (
                        <div>
                            <h1
                                className={
                                    'inline-block w-auto text-3xl lg:block sm:text-4xl main-heading'
                                }
                            >
                                Get In Touch
                            </h1>
                            <ContactForm
                                setShowContactForm={setShowContactForm}
                            />
                        </div>
                    ) : (
                        <>
                            <h1
                                className={
                                    'inline-block w-auto text-3xl lg:block sm:text-4xl lg:text-5xl main-heading'
                                }
                            >
                                Digital Solutions
                            </h1>
                            <span className={'text-base block font-normal'}>
                                <p>
                                    From responsive web design to cutting-edge
                                    app development, we transform your ideas
                                    into stunning, user-friendly experiences.
                                </p>
                                <div className={'md:text-right'}>
                                    <button
                                        className={'btn'}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setShowContactForm(true);
                                        }}
                                    >
                                        Get In Touch
                                    </button>
                                </div>
                            </span>
                        </>
                    )}
                </div>
            </div>
            <div
                className={
                    'app-bg border-t border-b border-primary py-2xl flex flex-col justify-center items-center min-h-500'
                }
            >
                <h2 className={'mb-xl'}>{"Brands We've Worked With"}</h2>
                <div>
                    <div className={'flex justify-center px-2xl flex-wrap'}>
                        <div className={logoContainerCssClass}>
                            <img src={currys} className={'w-28'} alt="Currys" />
                        </div>
                        <div className={logoContainerCssClass}>
                            <img
                                src={firstDirect}
                                className={'w-40'}
                                alt="First Direct"
                            />
                        </div>
                        <div className={logoContainerCssClass}>
                            <div className={'p-xs bg-aviva'}>
                                <img
                                    src={aviva}
                                    className={'w-40'}
                                    alt="Aviva"
                                />
                            </div>
                        </div>
                        <div className={logoContainerCssClass}>
                            <img
                                src={carphoneWarehouse}
                                className={logoCssClass}
                                alt="Carphone Warehouse"
                            />
                        </div>
                        <div className={logoContainerCssClass}>
                            <img
                                src={mobiles}
                                className={'w-40'}
                                alt="Mobiles.co.uk"
                            />
                        </div>
                        <div className={logoContainerCssClass}>
                            <div className={'bg-beacon rounded-sm px-sm'}>
                                <img
                                    src={beaconInternational}
                                    className={'w-28'}
                                    alt="Beacon International"
                                />
                            </div>
                        </div>
                        <div className={logoContainerCssClass}>
                            <img
                                src={healthAndHerApp}
                                className={'w-20'}
                                alt="Health and Her"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'bg-black py-2xl'}>
                <h2
                    className={
                        'inline-block border-b border-b-primary pt-md pb-sm'
                    }
                >
                    Our Services
                </h2>
                <div className={'grid xl:grid-cols-3 px-md text-left'}>
                    <div className={'p-md'}>
                        <Link
                            to={'/web-development'}
                            className={ctaLinkCssClass}
                        >
                            <div className={'flex flex-wrap justify-between'}>
                                <img
                                    src={screens}
                                    className="w-40 fill-white mb-lg"
                                    alt="Responsive Screens"
                                />
                                <div
                                    className={
                                        'hidden md:inline-block md:text-right'
                                    }
                                >
                                    <button className={'btn'}>View More</button>
                                </div>
                            </div>
                            <h3 className={'border-b border-primary pb-xs'}>
                                Responsive Websites
                            </h3>
                            <p>
                                Elevate your online presence with a
                                mobile-friendly website that adapts to all
                                devices.
                            </p>
                        </Link>
                    </div>

                    <div className={'p-md h-full'}>
                        <Link
                            to={'/app-development'}
                            className={ctaLinkCssClass}
                        >
                            <div className={'flex flex-wrap justify-between '}>
                                <div className={'flex mb-lg'}>
                                    <img
                                        src={apple}
                                        className="w-20 mr-sm"
                                        alt="Apple"
                                    />
                                    <img
                                        src={android}
                                        className="w-20"
                                        alt="Android"
                                    />
                                </div>
                                <div
                                    className={
                                        'hidden md:inline-block md:text-right'
                                    }
                                >
                                    <button className={'btn'}>View More</button>
                                </div>
                            </div>
                            <h3 className={'border-b border-primary pb-xs'}>
                                App Development
                            </h3>
                            <p>
                                Turn your mobile app visions into reality with
                                our professional app development services,
                                compatible with Apple and Android.
                            </p>
                        </Link>
                    </div>
                    <div className={'p-md h-full'}>
                        <Link to={'/ux-design'} className={ctaLinkCssClass}>
                            <div className={'flex flex-wrap justify-between '}>
                                <img
                                    src={uxDesign}
                                    className="w-24 mb-lg"
                                    alt="phone"
                                />
                                <div
                                    className={
                                        'hidden md:inline-block md:text-right'
                                    }
                                >
                                    <button className={'btn'}>View More</button>
                                </div>
                            </div>
                            <h3 className={'border-b border-primary pb-xs'}>
                                UX Design
                            </h3>
                            <p>
                                Cultivate exceptional user experiences that
                                captivate and retain your audience with our
                                unrivaled UX design expertise
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
