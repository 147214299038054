import logo from './logo.svg';
import menu from './svg/menu.svg';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import WebDevelopment from './pages/WebDevelopment';
import FourZeroFour from './pages/404';
import AppDevelopment from './pages/AppDevelopment';
import UXDesign from './pages/UXDesign';
import Technologies from './pages/Technologies';
import Contact from './pages/Contact';
import { useState } from 'react';

function App() {
    const linkCssClass = 'text-white hover:no-underline hover:text-primary';
    const [showMenuXs, setShowMenuXs] = useState(false);

    return (
        <BrowserRouter>
            <div
                className={`flex flex-col ${
                    process.env.NODE_ENV === 'development'
                        ? 'debug-screens'
                        : ''
                }`}
            >
                <header className="header md:flex justify-between items-center px-md md:px-3xl py-sm md:py-md absolute md:fixed top-0 w-full z-40 border-b-1">
                    <div className={'flex justify-between'}>
                        <Link to={'/'}>
                            <img
                                src={logo}
                                className="App-logo w-36 md:w-48"
                                alt="logo"
                            />
                        </Link>

                        <div>
                            <button
                                type={'button'}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setShowMenuXs((value) => !value);
                                }}
                            >
                                <img
                                    src={menu}
                                    className={'w-12 md:hidden'}
                                    alt={'menu'}
                                />
                            </button>
                        </div>
                    </div>

                    <ul
                        className={`${
                            showMenuXs ? 'block' : 'hidden'
                        } py-md md:flex md:items-center text-white mb-0 md:p-0 grow justify-end list-none gap-lg`}
                    >
                        <li className={'md:mb-0'}>
                            <Link
                                to={'/web-development'}
                                className={linkCssClass}
                            >
                                Web Development
                            </Link>
                        </li>
                        <li className={'md:mb-0'}>
                            <Link
                                to={'/app-development'}
                                className={linkCssClass}
                            >
                                App Development
                            </Link>
                        </li>
                        <li className={'md:mb-0'}>
                            <Link to={'/ux-design'} className={linkCssClass}>
                                UX Design
                            </Link>
                        </li>
                        <li className={'md:mb-0'}>
                            <Link to={'/technologies'} className={linkCssClass}>
                                Technologies
                            </Link>
                        </li>
                        <li className={'mb-0'}>
                            <Link to={'/contact'} className={linkCssClass}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </header>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/web-development"
                        element={<WebDevelopment />}
                    />
                    <Route
                        path="/app-development"
                        element={<AppDevelopment />}
                    />
                    <Route path="/ux-design" element={<UXDesign />} />
                    <Route path="/technologies" element={<Technologies />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="*" element={<FourZeroFour />} />
                </Routes>

                <div
                    className={
                        'bg-dark text-white text-right text-sm right-0 px-2xs py-3xs'
                    }
                >
                    E-Fox Web Solutions Ltd | VAT: 0000
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
