import reactImage from '../svg/react.svg';
import adobeCC from '../svg/tech/adobe-cc.svg';
import adobeIllustrator from '../svg/tech/adobe-illustrator.svg';
import adobePhotoshop from '../svg/tech/adobe-photoshop.svg';
import adobeXD from '../svg/tech/adobe-xd.svg';
import android from '../svg/tech/android.svg';
import aws from '../svg/tech/aws.svg';
import axios from '../svg/tech/axios.svg';
import bitbucket from '../svg/tech/bitbucket.svg';
import css3 from '../svg/tech/css3.svg';
import docker from '../svg/tech/docker.svg';
import gitLogo from '../svg/tech/git-logo.svg';
import html5 from '../svg/tech/html5.svg';
import ios from '../svg/tech/ios.svg';
import javascript from '../svg/tech/javascript.svg';
import jira from '../svg/tech/jira.svg';
import laravel from '../svg/tech/laravel.svg';
import mongoDB from '../svg/tech/mongodb.svg';
import mySql from '../svg/tech/mysql.svg';
import nodeJs from '../svg/tech/node-js.svg';
import php from '../svg/tech/php.svg';
import reactTech from '../svg/tech/react.svg';
import reactNative from '../svg/tech/react-native.svg';
import redux from '../svg/tech/redux.svg';
import symfony from '../svg/tech/symfony.svg';
import tailwindCss from '../svg/tech/tailwind-css.svg';
import typescriptTech from '../svg/tech/typescript.svg';
import vite from '../svg/tech/vite.svg';
import webpack from '../svg/tech/webpack.svg';
import wordpress from '../svg/tech/wordpress.svg';

function Technologies() {
    const headerCssClasses =
        'block border-b border-b-primary pt-md pb-sm mb-2xl text-left';
    const logoGridCssClasses =
        'grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 gap-lg mb-lg';
    const renderLogo = (text, logo, options = {}) => {
        const optionsObj = {
            logoWidthXS: 'w-20',
            logoWidth: 'md:w-28',
            logoCssClass: 'mx-auto',
            ...options,
        };
        return (
            <div
                className={'bg-white rounded p-md flex flex-col text-center'}
                style={{ minHeight: '200px' }}
            >
                <div className={'grow flex items-center'}>
                    <img
                        src={logo}
                        className={`h-auto mb-xs ${optionsObj.logoWidthXS} ${optionsObj.logoWidth} ${optionsObj.logoCssClass}`}
                        alt={text}
                    />
                </div>
                <span className={'text-primary text-lg font-bold'}>{text}</span>
            </div>
        );
    };

    return (
        <>
            <div
                className={
                    'bg-code min-h-screen flex justify-center items-center pt-28 pb-lg xl:pb-0 flex-wrap'
                }
            >
                <div className={'relative rounded-full bg-white p-xl mb-md '}>
                    <img
                        src={reactImage}
                        className={'w-20 md:w-52 h-auto'}
                        alt="React"
                    />
                </div>
                <div className={'flex items-center justify-center'}>
                    <div className={'max-w-768 px-2xl mx-auto'}>
                        <div className={'text-center md:text-left'}>
                            <h1 className={'main-heading'}>Technologies</h1>
                            <span
                                className={
                                    'text-white text-base block font-normal'
                                }
                            >
                                <p>
                                    At E-Fox Web Solutions, we specialize in
                                    delivering top-notch frontend solutions with
                                    a comprehensive approach extending to the
                                    backend. Leveraging leading technologies
                                    like React, React Native, JavaScript, and
                                    NodeJs, we excel in creating modern,
                                    cutting-edge applications.
                                </p>
                                <p>
                                    Industry-standard tools like Bitbucket and
                                    Git facilitate version control and
                                    collaborative development within our tech
                                    stack, reflecting our commitment to
                                    innovation in both frontend and backend
                                    development.
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'bg-black py-2xl'}>
                <div className={'px-xl'}>
                    <div
                        className={
                            'block border-animation p-lg bg-code hover:no-underline text-white h-full'
                        }
                    >
                        <h2 className={headerCssClasses}>Frontend</h2>
                        <div className={logoGridCssClasses}>
                            {renderLogo('CSS3', css3, {
                                logoWidthXS: 'w-16',
                                logoWidth: 'md:w-20',
                            })}
                            {renderLogo('HTML5', html5)}
                            {renderLogo('JavaScript', javascript)}
                            {renderLogo('TypeScript', typescriptTech)}
                            {renderLogo('React', reactTech)}
                            {renderLogo('React Native', reactNative)}
                            {renderLogo('Redux', redux)}
                            {renderLogo('NodeJS', nodeJs)}
                            {renderLogo('Axios', axios, {
                                logoWidth: 'md:w-32',
                            })}
                            {renderLogo('Tailwind CSS', tailwindCss, {
                                logoWidthXS: 'w-32',
                                logoWidth: 'md:w-40',
                            })}
                            {renderLogo('Vite', vite, {
                                logoWidthXS: 'w-16',
                                logoWidth: 'md:w-22',
                            })}
                            {renderLogo('Webpack', webpack, {
                                logoWidthXS: 'w-32',
                                logoWidth: 'md:w-40',
                            })}
                        </div>
                        <h2 className={headerCssClasses}>Backend</h2>
                        <div className={logoGridCssClasses}>
                            {renderLogo('MongoDB', mongoDB, {
                                logoWidthXS: 'w-32',
                                logoWidth: 'md:w-40',
                            })}
                            {renderLogo('PHP', php)}
                            {renderLogo('Laravel', laravel, {
                                logoWidthXS: 'w-16',
                                logoWidth: 'md:w-22',
                            })}
                            {renderLogo('Symfony', symfony, {
                                logoWidthXS: 'w-32',
                                logoWidth: 'md:w-40',
                            })}
                            {renderLogo('MySQL', mySql)}
                            {renderLogo('Wordpress', wordpress, {
                                logoWidth: 'md:w-22',
                            })}
                        </div>
                        <h2 className={headerCssClasses}>Tools and Devops</h2>
                        <div className={logoGridCssClasses}>
                            {renderLogo('AWS', aws)}
                            {renderLogo('Git', gitLogo)}
                            {renderLogo('Bitbucket', bitbucket, {
                                logoWidthXS: 'w-32',
                                logoWidth: 'md:w-40',
                            })}
                            {renderLogo('Jira', jira, {
                                logoWidthXS: 'w-32',
                                logoWidth: 'md:w-40',
                            })}
                            {renderLogo('Docker', docker, {
                                logoWidthXS: 'w-28',
                            })}
                        </div>
                        <h2 className={headerCssClasses}>Design</h2>
                        <div className={logoGridCssClasses}>
                            {renderLogo('Adobe XS', adobeXD)}
                            {renderLogo('Adobe Photoshop', adobePhotoshop)}
                            {renderLogo('Adobe Illustrator', adobeIllustrator)}
                            {renderLogo('Creative Cloud', adobeCC)}
                        </div>
                        <h2 className={headerCssClasses}>App</h2>
                        <div className={logoGridCssClasses}>
                            {renderLogo('Android', android, {
                                logoWidth: 'md:w-22',
                            })}
                            {renderLogo('IOS', ios, {
                                logoWidth: 'md:w-22',
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Technologies;
