import ContactForm from '../components/ContactForm/ContactForm';

function Contact() {
    return (
        <>
            <div className={'bg-main'}>
                <div
                    className={
                        'flex grow items-center py-md bg-trans max-w-768 px-3xl min-h-screen pt-28 pb-lg xl:pb-0'
                    }
                >
                    <div className={'w-full py-2xl text-left'}>
                        <h1
                            className={
                                'inline-block w-auto text-3xl lg:block sm:text-4xl main-heading'
                            }
                        >
                            Contact Us
                        </h1>
                        <p>
                            Share details about your project, and let us assist
                            you in achieving your goals. Provide your contact
                            information and a brief project description. We look
                            forward to connecting with you to explore how E-Fox
                            Web Solutions can contribute to your success. We
                            endeavor to respond within 2 working days.
                        </p>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;
